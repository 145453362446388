var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payout-config"},[_c('CCard',[_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0",attrs:{"outlined":"","striped":"","hover":"","items":_vm.data,"fields":_vm.header,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"order",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))])}},{key:"platform",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.platform))])}},{key:"token",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.token))])}},{key:"payout",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.payout))])}},{key:"est",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.est))])}},{key:"maxValue",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('CInput',{attrs:{"value":item.maxValue}})],1)}}])}),_c('div',{staticClass:"text-right"},[_c('CButton',{staticClass:"pl-4 pr-4",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$T('PAYOUT_CONFIG_PAGE_BUTTON_SAVE')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }