<template>
  <div class="payout-config">
    <CCard>
      <CCardBody>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="data"
          :fields="header"
          head-color="light"
          no-sorting
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">{{ index + 1 }}</td>

          <td class="text-center" slot="platform" slot-scope="{ item }">{{ item.platform }}</td>
          <td class="text-center" slot="token" slot-scope="{ item }">{{ item.token }}</td>
          <td class="text-center" slot="payout" slot-scope="{ item }">{{ item.payout }}</td>
          <td class="text-center" slot="est" slot-scope="{ item }">{{ item.est }}</td>

          <td class="text-center" slot="maxValue" slot-scope="{ item }">
            <CInput :value="item.maxValue" />
          </td>
        </CDataTable>
        <div class="text-right">
          <CButton color="primary" class="pl-4 pr-4">{{$T('PAYOUT_CONFIG_PAGE_BUTTON_SAVE')}}</CButton>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          platform: "ETH",
          token: "INFT",
          payout: 5000,
          est: 100,
          maxValue: 6000
        },
        {
          platform: "ETH",
          token: "INFT",
          payout: 5000,
          est: 100,
          maxValue: 6000
        },
        {
          platform: "ETH",
          token: "INFT",
          payout: 5000,
          est: 100,
          maxValue: 6000
        },
        {
          platform: "ETH",
          token: "INFT",
          payout: 5000,
          est: 100,
          maxValue: 6000
        }
      ],
      header: [
        { key: "order", label: "#", _classes: "text-center" },
        { key: "platform", label: this.$t('PAYOUT_CONFIG_PAGE_TABLE_COL_PLATFORM'), _classes: "text-center" },
        { key: "token", label: this.$t('PAYOUT_CONFIG_PAGE_TABLE_COL_TOKEN'), _classes: "text-center" },
        { key: "payout", label: this.$t('PAYOUT_CONFIG_PAGE_TABLE_COL_PAYOUT'), _classes: "text-center" },
        { key: "est", label: this.$t('PAYOUT_CONFIG_PAGE_TABLE_COL_EST_PAYOUT'), _classes: "text-center" },
        { key: "maxValue", label: this.$t('PAYOUT_CONFIG_PAGE_TABLE_COL_MAX_PAYOUT'), _classes: "text-center" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.payout-config /deep/ {
  .form-group {
    margin-bottom: 0;
  }
}
</style>